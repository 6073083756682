import axios, { AxiosResponse } from 'axios';
import auth from '@udacity/ureact-hoth';

/*
 * This file is copied from the library @udacity/ureact-api-service due to it being deprecated and needing to update dependency versions.
 * The only modifications are minor type additions due to the the previous library not being in TypeScript.
 * Reference: https://github.com/udacity/ureact-api-service
 * TODO - Refactor to remove Axios
 */

const REQUEST_TIMEOUT_MS = 10000;

class ApiService {
  async makeGetRequest(apiUrl, path, axiosOpts = {}) {
    return this._request({
      url: apiUrl + path,
      method: 'get',
      axiosOpts
    });
  }

  async makePostRequest(apiUrl, path, data, axiosOpts = {}) {
    return this._request({
      url: apiUrl + path,
      method: 'post',
      data,
      axiosOpts
    });
  }

  async makePutRequest(apiUrl, path, data, axiosOpts = {}) {
    return this._request({
      url: apiUrl + path,
      method: 'put',
      data,
      axiosOpts
    });
  }

  async makePatchRequest(apiUrl, path, data, axiosOpts = {}) {
    return this._request({
      url: apiUrl + path,
      method: 'patch',
      data,
      axiosOpts
    });
  }

  async makeDeleteRequest(apiUrl, path, axiosOpts = {}) {
    return this._request({
      url: apiUrl + path,
      method: 'delete',
      axiosOpts
    });
  }

  async makeGqlRequest(apiUrl, data, axiosOpts = {}) {
    return this._request({
      url: `${apiUrl}/graphql`,
      method: 'post',
      data,
      axiosOpts
    }).then((response: AxiosResponse | any) => {
      const { errors } = response;
      if (errors && errors.length > 0) {
        throw new ApiError({
          message: errors[0].message,
          status: errors[0].status
        });
      } else {
        return response;
      }
    });
  }

  async _request({
    url,
    method,
    data,
    axiosOpts
  }: {
    url: string;
    method: string;
    data?: any;
    axiosOpts?: any;
  }) {
    const jwt = auth.getJWT();

    const axiosConfig = {
      timeout: REQUEST_TIMEOUT_MS,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      ...axiosOpts
    };

    if (jwt) {
      axiosConfig.headers.Authorization = `Bearer ${jwt}`;
    }

    return axios({
      method,
      url,
      data,
      ...axiosConfig
    })
      .then((response) => {
        // by default this returns response.data. To access full response (i.e. for response headers)
        // pass {returnFullResponse: true} in axiosOpts
        const fullResponse = axiosOpts.returnFullResponse === true;
        return fullResponse ? response : response.data;
      })
      .catch((err) => {
        const response = err.response;

        if (!response) {
          throw new ApiError({
            message: `ApiError - undefined response at ${method} ${url}`,
            response: err
          });
        }

        const message =
          response.data.message || response.data.error || response.statusText;
        throw new ApiError({ message, status: response.status, response });
      });
  }
}

export class ApiError extends Error {
  status?: number;
  response?: any;

  constructor({
    message,
    status,
    response
  }: {
    message: string;
    status?: number;
    response?: any;
  }) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
    this.response = response;
  }
}

export default new ApiService();
